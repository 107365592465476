import { attributeMapping } from "./constants"

export const toHexColor = (color) => {
  const r = Math.round(Math.max(0, Math.min(color.r * 255, 255)))
    .toString(16)
    .padStart(2, "0");
  const g = Math.round(Math.max(0, Math.min(color.g * 255, 255)))
    .toString(16)
    .padStart(2, "0");
  const b = Math.round(Math.max(0, Math.min(color.b * 255, 255)))
    .toString(16)
    .padStart(2, "0");
  return `${r}${g}${b}`;
};

export const prepConfiguration = (colors) => {
  if (!colors || !Object.keys(colors).length) return null;
  return Object.entries(attributeMapping).reduce((output, [attrName, key]) => {
    if (!colors[key]) return output;
    return Object.assign(output, {
      [attrName]: colors[key],
    });
  }, {});
};

export const generateUUID = () => {
  const _lut = [];

  for (let i = 0; i < 256; i++) {
    _lut[i] = (i < 16 ? "0" : "") + i.toString(16);
  }

  // http://stackoverflow.com/questions/105034/how-to-create-a-guid-uuid-in-javascript/21963136#21963136

  const d0 = (Math.random() * 0x100000000) | 0;
  const d1 = (Math.random() * 0x100000000) | 0;
  const d2 = (Math.random() * 0x100000000) | 0;
  const d3 = (Math.random() * 0x100000000) | 0;
  const uuid = `${
    _lut[d0 & 0xff] +
    _lut[(d0 >> 8) & 0xff] +
    _lut[(d0 >> 16) & 0xff] +
    _lut[(d0 >> 24) & 0xff]
  }-${_lut[d1 & 0xff]}${_lut[(d1 >> 8) & 0xff]}-${
    _lut[((d1 >> 16) & 0x0f) | 0x40]
  }${_lut[(d1 >> 24) & 0xff]}-${_lut[(d2 & 0x3f) | 0x80]}${
    _lut[(d2 >> 8) & 0xff]
  }-${_lut[(d2 >> 16) & 0xff]}${_lut[(d2 >> 24) & 0xff]}${_lut[d3 & 0xff]}${
    _lut[(d3 >> 8) & 0xff]
  }${_lut[(d3 >> 16) & 0xff]}${_lut[(d3 >> 24) & 0xff]}`;

  // .toUpperCase() here flattens concatenated strings to save heap memory space.
  return uuid.toUpperCase();
};
