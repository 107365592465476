import styled from "styled-components";

export const Wrapper = styled.div`
  height: 100vh;
  width: 100vw;
  font-family: "Inter", sans-serif;
  background: #d0d0d0;
`;

export const ChatWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 12px;
`;

export const ThreekitLogoWrapper = styled.a`
  position: fixed;
  bottom: 40px;
  right: 40px;
  font-size: 13px;

  pointer-events: all;
  color: black;
  text-decoration: none;
`;
