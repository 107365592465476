import styled from "styled-components";
import { theme } from "../../constants"

const COLORS = {
  sent: "#4D416B",
  response: "#F1F3F7",
};

const AVATAR_SIZE = "36px";
const CONFIGURATION_BTN_SIZE = "32px";
const MESSAGE_WIDTH = "200px";

export const Wrapper = styled.div`
  height: max-content;
  width: ${(props) => (props.collapsed ? "36px" : "360px")};
  max-height: ${(props) => (props.collapsed ? "36px" : "1000px")};
  border-radius: ${(props) => (props.collapsed ? "18px" : "3px")};
  overflow: hidden;
  /* padding: 12px; */
  background: #fcfcfc;
  border-radius: 18px;
  transition: all 0.3s;
`;

export const Content = styled.div`
  padding: 14px 18px;
  width: 360px;
`;

export const Title = styled.div`
  font-weight: bold;
  font-size: 18px;
  text-align: center;
  margin-top: 8px;
`;

export const Line = styled.hr`
  width: 84px;
  height: 1px;
  outline: none;
  border: none;
  background: #3e597344;
  margin-top: 12px;
  margin-bottom: 12px;
  `;

export const ChatArea = styled.div`
  margin-bottom: 12px;
  height: 300px;
  overflow-y: scroll;
  mask-image: linear-gradient(to top, black 92%, transparent 100%);
  
  & > div:nth-child(1) {
    padding-bottom: 12px;
    display: flex;
    flex-direction: column;
    justify-content: end;

  }
`;

export const WidgetButton = styled.button`
  position: absolute;
  height: 36px;
  width: 36px;
  border-radius: 15px;
  background: none;
  border: none;
  outline: none;
  cursor: pointer;
`;

export const AvatarWrapper = styled.div`
  height: ${AVATAR_SIZE};
  width: ${AVATAR_SIZE};
  border-radius: 50%;
  background: ${theme.green};
  & > div:nth-child(1) {
    width: max-content;
    position: relative;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    color: #fff;
    font-size: 14px;
  }
`;

export const Message = styled.div`
  height: max-content;
  padding: 20px;
  max-width: ${MESSAGE_WIDTH};
  font-size: 11px;

  border-radius: 8px;
`;

export const MessageWrapper = styled.div`
  display: grid;
  grid-gap: 6px;
  margin-top: ${props => props.isMessageChain ? '4px' : '16px'};
`;
export const SentMessageWrapper = styled(MessageWrapper)`
  grid-template-columns: auto max-content ${AVATAR_SIZE};
`;
export const ResponseMessageWrapper = styled(MessageWrapper)`
  grid-template-columns: ${AVATAR_SIZE} max-content ${CONFIGURATION_BTN_SIZE};
`;

export const SentMessageText = styled(Message)`
  border-top-right-radius: 0px;
  background: ${COLORS.sent};
  color: white;
  box-shadow: -1px 1.5px 1px #11111133;
`;

export const ResponseMessageText = styled(Message)`
  border-top-left-radius: 0px;
  background: ${COLORS.response};
  box-shadow: 1px 1px 1px #11111133;
`;

export const ConfigurationButton = styled.button`
  height: ${CONFIGURATION_BTN_SIZE};
  width: ${CONFIGURATION_BTN_SIZE};
  border-radius: 50%;
  outline: none;
  border: none;
  cursor: pointer;
  transition: all 0.3s;
  background: ${theme.mint}aa;
  
  &:hover {
    box-shadow: 1px 1.5px 1px #11111133;
    background: ${theme.mint}ff;
  }
`;

export const NewMesageArea = styled.div`
  display: grid;
  grid-template-columns: auto max-content;
  grid-gap: 6px;
`;

export const MessageArea = styled.textarea`
  resize: none;
  height: 40px;
  outline: none;
  padding: 12px;
  /* font-size: 16px; */
  border-radius: 12px;
  max-height: 330px;
  border: 1px solid #bfbfbf;

  transition: all 0.1s;

  &::-webkit-scrollbar {
    width: 0px;
  }
`;

export const SubmitButton = styled.button`
  height: 42px;
  width: 42px;
  border-radius: 50%;
  background: ${theme.darkGreen}bb;
  color: white;
  cursor: pointer;
  outline: none;
  border: none;
  transition: all 0.3s;

  &:hover {
    box-shadow: 2px 2px 3px #11111133;
    background: ${theme.darkGreen}ff;
  }
`;
