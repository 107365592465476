export const API_URL = process.env.REACT_APP_API_URL || window.location.origin;

export const shoeKeys = [
  "Laces",
  "Back-Tab_Mesh-Detail",
  "Back-Tab_Mesh",
  "Interior",
  "Label",
  "Main_Mesh",
  "Tongue_Detail",
  "Laces_Tabs",
  "Side_Wrap",
  "Sole-Wrap",
  "Sole_Color",
  "Side_Mesh",
];

export const initialColors = {
  Laces: "000000",
  "Back-Tab_Mesh-Detail": "82cda9",
  "Back-Tab_Mesh": "ffffff",
  Interior: "ffffff",
  Label: "29826b",
  Main_Mesh: "ffffff",
  Tongue_Detail: "82cda9",
  Laces_Tabs: "000000",
  Side_Wrap: "003233",
  "Sole-Wrap": "29826b",
  Sole_Color: "000000",
  Side_Mesh: "82cda9",
};

/*const test = {
  "Quarter Overlay": "111111",
  Laces: "111111", // ADD
  Tip: "111111", // REMOVE
  Foxing: "111111",
  "Foxing Accent": "111111", // ADD
  Eyestay: "111111",
  "Tongue Accent": "111111", // ADD
  Vamp: "f0f0f0",
  Quarter: "f0f0f0",
  Collar: "f0f0f0", // REMOVE
  Logo: "1111aa",
  Midsole: "1111aa",
  Outsole: "1111aa",
  "Collar Lining": "1111aa",
};*/

export const attributeMapping = {
  Laces: "Laces",
  "Back-Tab_Mesh-Detail": "Eyestay",
  "Back-Tab_Mesh": "Foxing",
  Interior: "Collar Lining",
  Label: "Label",
  Main_Mesh: "Vamp",
  Tongue_Detail: "Tongue Accent",
  Laces_Tabs: "Eyestay",
  Side_Wrap: "Quarter Overlay",
  "Sole-Wrap": "Midsole",
  Sole_Color: "Outsole",
  Side_Mesh: "Quarter",
};

export const theme = {
  green: "#2A826B",
  darkGreen: "#044849",
  mint: "#78C4A2",
  black: "#021B15",
  peach: "#FFBA9B",
  orange: "#FA8D71",
  neonOrange: "#FF6B42",
  grey: "#E8EEF0",
};
