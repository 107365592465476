const Threekit =({ color = "black", ...props }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill={color}
      viewBox="0 0 500 555.2"
      {...props}
    >
      <path
        fill="inherit"
        d="M465.45,119.66,232.73,3.3a30.71,30.71,0,0,0-27.64.08V3.13L141.86,34.75l.21.12a30.73,30.73,0,0,1,28.16-.32L403,150.91a62.48,62.48,0,0,1,34.55,55.9V461.46a30.86,30.86,0,0,1-17.88,28h0v.25l63.27-31.63-.21-.13A30.87,30.87,0,0,0,500,430.21V175.56A62.48,62.48,0,0,0,465.45,119.66Z"
      />
      <path
        fill="inherit"
        d="M340.45,182.16,107.73,65.8a30.71,30.71,0,0,0-27.64.08v-.25L16.86,97.25l.21.12A30.85,30.85,0,0,0,0,125V379.65a62.49,62.49,0,0,0,34.55,55.9L267.27,551.91a30.65,30.65,0,0,0,27.35,0h0v.25l63.27-31.63-.21-.13A30.87,30.87,0,0,0,375,492.71V238.06A62.48,62.48,0,0,0,340.45,182.16ZM312.5,269.31V387L222.05,341.8a62.49,62.49,0,0,1-34.55-55.9V168.18L278,213.41A62.48,62.48,0,0,1,312.5,269.31ZM32.87,94c-.8,0-1.61-.18-2.4-.16C31.26,93.81,32.07,94,32.87,94Zm-8.95.79c-.1,0-.22,0-.32.06C23.7,94.83,23.82,94.82,23.92,94.79ZM301.58,547.42a2,2,0,0,0,.23-.18Zm5.26-5.83c.22-.32.49-.6.7-.93C307.33,541,307.06,541.27,306.84,541.59Zm3.64-7.11c.21-.59.54-1.1.71-1.71C311,533.38,310.7,533.89,310.48,534.48ZM97.05,404.3A62.49,62.49,0,0,1,62.5,348.4V105.68L45.23,97.05a31.39,31.39,0,0,0-7.5-2.6,30.68,30.68,0,0,1,7.5,2.6L125,136.93V317.15a62.49,62.49,0,0,0,34.55,55.9l153,76.48V512Z"
      />
    </svg>
  );
}

export default Threekit